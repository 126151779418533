
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import type { ArticleButtonBlokInterface } from '@energysage/storyblok-shared';

export default defineComponent({
    name: 'ArticleButton',
    props: {
        blok: {
            type: Object as PropType<ArticleButtonBlokInterface>,
            required: true,
        },
    },
});
